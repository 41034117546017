// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-content-js": () => import("./../../src/pages/AboutUsContent.js" /* webpackChunkName: "component---src-pages-about-us-content-js" */),
  "component---src-pages-aboutus-js": () => import("./../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-how-to-develop-an-ai-strategy-for-your-business-js": () => import("./../../src/pages/blog/how-to-develop-an-ai-strategy-for-your-business.js" /* webpackChunkName: "component---src-pages-blog-how-to-develop-an-ai-strategy-for-your-business-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-solutions-medpolicyassistant-js": () => import("./../../src/pages/solutions/medpolicyassistant.js" /* webpackChunkName: "component---src-pages-solutions-medpolicyassistant-js" */),
  "component---src-pages-solutions-medpolicyassistantcontent-js": () => import("./../../src/pages/solutions/medpolicyassistantcontent.js" /* webpackChunkName: "component---src-pages-solutions-medpolicyassistantcontent-js" */)
}

